.Separator {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
}

.Separator::before,
.Separator::after {
  content: '';
  flex: 1;
  height: 1px;
}

.Separator::before {
  margin-right: 8px;
  background: linear-gradient(to right, transparent, white);
}
.Separator::after {
  margin-left: 8px;
  background: linear-gradient(to right, white, transparent);
}

.Separator__heading {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  font-size: 1rem;
}

@media (orientation: portrait) {
  .Separator__heading {
    font-size: 1.5rem;
  }
}
