.HeroMensajes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  place-self: center start;
  gap: 2rem;
}

.HeroMensajes__titulo {
  font-size: 2.5rem;
  line-height: 1.35;
  font-weight: bold;
}

.HeroMensajes__bajada {
  line-height: 1.35;
  font-size: 1.25rem;
  max-width: 40ch;
}

.HeroMensajes__boton {
  background-color: black;
  color: white;
  padding: 1rem 1.5rem;
  border-radius: .35rem;
  font-weight: 600;
  font-size: 1.25rem;
}

.HeroMensajes__logo {
  height: .75rem;
}

@media (orientation: portrait) {

  .HeroMensajes {
    grid-column: span 2;
    width: 100%;
    align-items: center;
    gap: 5rem;
  }

  .HeroMensajes__titulo {
    text-align: center;
    font-size: 4rem;
  }
  
  .HeroMensajes__bajada {
    text-align: center;
    line-height: 1.5;
    font-size: 2.5rem;
  }

  .HeroMensajes__boton {
    font-size: 2rem;
    padding: 2rem 3rem;
    width: 100%;
  }

  .HeroMensajes__logo {
    height: 2rem;
  }
}