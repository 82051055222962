.BuscadorIntegraciones__overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, .35);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
}

.BuscadorIntegraciones {
  position: absolute;
  background-color: rgba(240, 240, 240);
  box-shadow:
    0 .25rem .15rem rgba(0, 0, 0, .15),
    0 .25rem 1rem rgba(0, 0, 0, .15);
  z-index: 20;
  top: 5rem;
  left: 5rem;
  right: 5rem;
  bottom: 5rem;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.BuscadorIntegraciones__boton_cerrar {
  right: .5rem;
  top: .5rem;
  position: absolute;
  font-size: 1.5rem;
  opacity: .5;
}

.BuscadorIntegraciones__input {
  min-height: 4rem;
  width: 75%;
  font-size: 2rem;
  text-align: center;
  z-index: 2;
  border: none;
  outline: none;
  border-radius: .5rem;
}

.BuscadorIntegraciones__contenedor_integraciones {
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  justify-content: center;
  gap: 1.5rem;
  overflow-y: auto;
  padding: .5rem;
  width: 100%;
}

.BuscadorIntegraciones__integracion {
  height: 100%;
  width: calc(50% - .75rem);
  min-width: calc(50% - .75rem);
  border-radius: .25rem;
  background-color: white;
  box-shadow:
    0 .25rem .15rem rgba(0, 0, 0, .15),
    0 .25rem 1rem rgba(0, 0, 0, .15);
  height: 4rem;
  padding: .5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BuscadorIntegraciones__logo_integracion {
  max-width: 60%;
  max-height: 75%;
}

.BuscadorIntegraciones__contenedor_mensaje {
  flex: 1;
  flex-direction: column;
  margin-top: 2rem;
  gap: 1rem;
  display: flex;
}

.BuscadorIntegraciones__mensaje {
  font-size: 1.5rem;
}

.BuscadorIntegraciones__boton {
  background-color: black;
  color: white;
  padding: .75rem 1.5rem;
  border-radius: .35rem;
  font-weight: 600;
  font-size: 1.25rem;
}