.Contacto {
  flex: 1;
  width: 100%;
  max-width: 1500px;
  display: grid;
}

.Contacto__contenedor_central {
  display: grid;
  grid-template-columns: 1fr 1.25fr;
  gap: 5rem;
  place-self: center;
  position: relative;
}

.Contacto__contenedor_texto {
  place-self: start end;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.Contacto__bajada {
  font-size: 0.9rem;
  opacity: 0.8;
  max-width: 35ch;
  line-height: 1.5;
}

.List {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.List__item {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 0.3rem;
}

.List__icon {
  transform: translateY(0.1rem);
}

.Contacto__bajada > ul {
  padding: 1.2rem 0 1.2rem 0.5rem;
}

.Contacto__bajada > ul > li {
  vertical-align: middle;
}

.Contacto__bajada > ul > li:not(:last-child) {
  margin-bottom: 0.4rem;
}

.Contacto__titulo {
  font-size: 2.25rem;
  font-weight: bold;
}

.Contacto__contenedor_formulario {
  place-self: center start;
}

.Contacto__formulario {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.Contacto__campo {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  grid-column: span 2;
  width: 30ch;
}

.Contacto__campo--pequeño {
  grid-column: span 1;
}

.Contacto__input {
  padding: 1rem;
  border-radius: 0.25rem;
  border: 1px solid lightgray;
  font-size: 0.8rem;
}

.Contacto__label_campo {
  font-weight: bold;
  font-size: 0.75rem;
}

.Contacto__select {
  padding: 1rem;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  border: 1px solid lightgray;
}

.Contacto__boton {
  background-color: black;
  color: white;
  padding: 0.75rem 0.25rem;
  border-radius: 0.35rem;
  font-weight: 600;
  font-size: 0.9rem;
  margin-top: 2rem;
}

.Contacto__contenedor_mensaje_enviado {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Contacto__mensaje_enviado {
  background-color: white;
  padding: 2rem;
  box-shadow: 0 0 0.3rem 2rem rgba(255, 255, 255, 0.25),
    0 0 0.3rem -0.1rem rgba(0, 0, 0, 0.35);
  z-index: 2;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  text-align: center;
  animation: aparece-mensaje-enviado 0.5s ease both;
}

.Contacto__mensaje_enviado_titulo {
  font-weight: bold;
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

@media (orientation: portrait) {
  .Contacto__contenedor_central {
    display: flex;
    flex-direction: column;
  }

  .Contacto__titulo {
    font-size: 3rem;
    text-align: center;
  }

  .Contacto__bajada {
    text-align: center;
    font-size: 2rem;
  }

  .Contacto__contenedor_formulario {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .Contacto__formulario {
    width: 100%;
    gap: 2rem;
  }

  .Contacto__label_campo {
    font-weight: bold;
    font-size: 2rem;
  }

  .Contacto__campo {
    width: 100%;
    gap: 0.5rem;
  }

  .Contacto__input {
    padding: 2rem;
    font-size: 2rem;
  }

  .Contacto__select {
    padding: 2rem;
    font-size: 2rem;
  }

  .Contacto__boton {
    font-size: 2rem;
    padding: 1.5rem 2.5rem;
    width: 100%;
  }

  .Contacto__mensaje_enviado {
    font-size: 2rem;
  }

  .Contacto__mensaje_enviado_titulo {
    font-size: 3rem;
  }
}

@keyframes aparece-mensaje-enviado {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
