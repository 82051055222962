.Heading {
  margin-bottom: 1.5rem;
  color: inherit;
}

.Heading--small .Heading__title {
  font-size: 1.5rem;
}

.Heading--medium .Heading__title {
  font-size: 2rem;
}

.Heading--large .Heading__title {
  font-size: 2.5rem;
}

.Heading__title {
  margin: 0;
  font-weight: bold;
  color: inherit;
  line-height: 1.25;
}

.Heading__subtitle {
  margin-top: 0.5rem;
  color: inherit;
  font-size: 1rem;
}

@media (orientation: portrait) {
  .Heading--small .Heading__title {
    font-size: 2rem;
  }
  
  .Heading--medium .Heading__title {
    font-size: 2.5rem;
  }
  
  .Heading--large .Heading__title {
    font-size: 3rem;
  }
}
