.DiagramaTecnologia {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  aspect-ratio: 2 / 1;
}

.DiagramaTecnologia__captura {
  width: 100%;
  box-shadow:
    0 .25rem .15rem rgba(0, 0, 0, .15),
    0 .25rem 1rem rgba(0, 0, 0, .15);
  border-radius: .25rem;
  position: absolute;
  left: 0;
  top: 3rem;
}

.DiagramaTecnologia__captura--back {
  position: absolute;
  left: 3rem;
  top: -2.25rem;
  z-index: -1;
  opacity: 0.6;
}