.Tecnologia {
  width: 100%;
  display: grid;
  grid-template: auto 1fr / 1fr 1fr;
  place-items: center center;
  padding: 3rem 8rem;
  gap: 5rem;
  margin-top: 5rem;
}

.Tecnologia__textos {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  line-height: 1.35;
  font-size: 1.25rem;
}

.Tecnologia__subtitulo {
  font-weight: bold;
  font-size: 1.5rem;
}

.Tecnologia__contenedor_botones {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.Tecnologia__boton {
  background-color: black;
  color: white;
  padding: .75rem 1.5rem;
  border-radius: .35rem;
  font-weight: 600;
  font-size: 1.25rem;
}

.Tecnologia__boton--secundario {
  background-color: gray;
}

.Tecnologia__contenedor_grafica {
  place-self: stretch stretch;
}

@media (orientation: portrait) {
  .Tecnologia__contenedor_grafica {
    display: none;
  }

  .Tecnologia__subtitulo {
    font-size: 2.5rem;
  }

  .Tecnologia__textos {
    grid-column: 1 / -1;
    place-self: center center;
    text-align: center;
    font-size: 2rem;
  }

  .Tecnologia__contenedor_botones {
    flex-direction: column;
  }

  .Tecnologia__boton {
    text-align: center;
    font-size: 2rem;
    padding: 2rem 1.5rem;
  }
}