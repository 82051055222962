:root {
  --color-rojo: #ed3324;
  --color-azul: #005bf1;
  --color-purpura: #a506e9;
  --color-verde: #00d200;
}

@keyframes Superior__bg {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}