.BarraSuperior__contenedor {
  position: sticky;
  top: 0;
  z-index: 4;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.BarraSuperior {
  height: 4rem;
  font-size: 2rem;
  box-shadow: 0 0 .3rem -.1rem rgba(0, 0, 0, .2);
  padding: 0 8rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.BarraSuperior__barrita {
  background: linear-gradient(
    45deg,
    var(--color-rojo) 0 25%,
    var(--color-azul),
    var(--color-purpura) 75%
  );
  background-size: 400% 400%;
  animation: Superior__bg 10s ease-in-out infinite;
  height: .35rem;
}

.BarraSuperior__logo {
  width: 4.25rem;
  cursor: pointer;
  place-self: center start;
}

.BarraSuperior__navegacion {
  display: flex;
  align-items: center;
  gap: 1.75rem;
  place-self: center end;
}

.BarraSuperior__navegacion_link {
  background: none;
  font-weight: 500;
  font-size: .8rem;
}

.BarraSuperior__navegacion_boton {
  background-color: black;
  color: white;
  padding: .55rem .75rem;
  border-radius: .35rem;
  font-weight: 600;
  font-size: .8rem;
}

@media (orientation: portrait) {

  .BarraSuperior {
    height: 8rem;
    padding: 0 3rem;
  }

  .BarraSuperior__logo {
    width: 9.25rem;
  }

  .BarraSuperior__barrita {
    height: 1rem;
  }

  .BarraSuperior__navegacion_link {
    display: none;
  }

  .BarraSuperior__navegacion_boton {
    font-size: 2rem;
    padding: 1.5rem 2.5rem;
  }
}