.Clientes {
  --int-padding: 6rem;
  clip-path: polygon(
    0% 0%,
    100% var(--int-padding),
    100% calc(100% - var(--int-padding)),
    0% 100%
  );
  background: linear-gradient(
    45deg,
    var(--color-rojo) 0 25%,
    var(--color-azul),
    var(--color-purpura) 75%
  );
  background-size: 400% 400%;
  animation: Superior__bg 10s ease-in-out infinite;
  width: 100%;
  padding: 8rem 0 6rem 0;
  clip-path: polygon(0% var(--int-padding), 100% 0%, 100% 100%, 0% 100%);
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.Clientes__list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.Clientes__container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 3rem;
}

.Clientes__group {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.Clientes__logos {
  display: flex;
  justify-content: space-evenly;
  z-index: 3;
  gap: 5rem;
}

.Clientes__logo {
  max-width: 8rem;
  max-height: 2.5rem;
}

.Clientes__logo_cero {
  height: 1.5rem;
  margin-right: 0.25rem;
  transform: translateY(0.25rem);
}

@media (orientation: portrait) {
  .Clientes {
    clip-path: polygon(0% 4rem, 100% 0%, 100% 100%, 0% 100%);
    padding: 10rem 2rem 6rem 2rem;
  }

  .Clientes__container {
    flex-direction: column;
  }

  .Clientes__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .Clientes__logo {
    max-width: 10rem;
    max-height: 4rem;
  }

  .Clientes__logo_cero {
    height: 2rem;
  }

  .Clientes .Heading__title {
    text-align: center;
  }

}
