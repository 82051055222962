.Hero__fondo {
  width: 100%;
  display: flex;
  justify-content: center;
  transform: translateY(-4rem);
}

.Hero {
  width: 100%;
  display: grid;
  grid-template: 1fr auto 15% / 1fr 1fr;
  padding: 10rem 8rem 0 8rem;
  color: black;
}

.Hero__bajada {
  grid-column: span 2;
}

.Hero__logos {
  grid-column: span 2;
}

@media (orientation: portrait) {
  .Hero {
    padding: 10rem 4rem 0 4rem;
  }
}
