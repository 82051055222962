.Capacidad {
  width: 100%;
  display: grid;
  grid-template: auto 1fr / 1fr 1fr;
  padding: 4rem 8rem 5rem 8rem;
  gap: 5rem;
  margin: 5rem 0 2rem 0;
}

.Capacidad__titulo {
  grid-column: 1 / -1;
  font-size: 2rem;
  font-weight: bold;
}

.Capacidad__textos {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  line-height: 1.35;
  font-size: 1.25rem;
}

.Capacidad__subtitulo {
  font-weight: bold;
  font-size: 1.5rem;
}

.Capacidad__contenedor_botones {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.Capacidad__boton {
  background-color: black;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.35rem;
  font-weight: 600;
  font-size: 1.25rem;
}

.Capacidad__boton--secundario {
  background-color: gray;
}

@media (orientation: portrait) {
  .Capacidad__contenedor_grafica {
    display: none;
  }

  .Capacidad__titulo {
    text-align: center;
    font-size: 3rem;
  }

  .Capacidad__subtitulo {
    font-size: 2rem;
  }

  .Capacidad__textos {
    grid-column: 1 / -1;
    place-self: center center;
    text-align: center;
    font-size: 2rem;
  }

  .Capacidad__contenedor_botones {
    justify-content: center;
    flex-direction: column;
  }

  .Capacidad__boton {
    text-align: center;
    font-size: 2rem;
    padding: 2rem 1.5rem;
  }
}
