.SlideCelularGenerica2022 {
  animation: aparece-slide 1s ease;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  align-items: center;
  --color-rojo: #ed3324;
  --color-azul: #005bf1;
  --color-purpura: #a506e9;
  --color-verde: #00d200;
  --color-texto: black;
  --color-blanco: white;
  --color-negro: black;
  --color-fondo-beneficios: #f2f2f2;
  overscroll-behavior: none;
  scrollbar-width: 0.5rem;
  scrollbar-color: var(--color-purpura) var(--color-fondo-beneficios);
}

.SlideCelularGenerica2022::-webkit-scrollbar {
  width: 0.5rem;
  background-color: var(--color-purpura);
}

.SlideCelularGenerica2022::-webkit-scrollbar-thumb {
  border-radius: 0.15rem;
  background-color: var(--color-fondo-beneficios);
}

.SlideCelularGenerica2022__titulo {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.75rem;
}

.SlideCelularGenerica2022__texto {
  font-size: 3.25rem;
  text-align: left;
  line-height: 1.25;
  max-width: 20ch;
}

.SlideCelularGenerica2022__celular {
  color: var(--color-negro);
  height: 125vmin;
  background: #f7f7f7;
  width: 55vmin;
  border-radius: 5vmin;
  box-shadow: 0 0 0 0.7vmin #2b292a, -0.2vmin -0.2vmin 0 0.7vmin #b0b0b0,
    0 0 0 1.4vmin #656565;
  position: absolute;
  right: calc(50% - 26vmin);
  top: 5%;
  font-family: 'Helvetica', sans-serif;
  display: flex;
  flex-direction: column;
}

.SlideCelularGenerica2022__celular::before {
  content: '';
  position: absolute;
  width: 0.5vmin;
  top: 15vmin;
  height: 10vmin;
  background: #444;
  left: -1.8vmin;
  border-radius: 1vmin 0 0 1vmin;
}

.SlideCelularGenerica2022__celular::after {
  content: '';
  position: absolute;
  width: 0.6vmin;
  top: 8vmin;
  height: 7vmin;
  background: #333;
  right: -1.8vmin;
  border-radius: 0 2vmin 2vmin 0;
  box-shadow: 0 9vmin #333;
}

.SlideCelularGenerica2022__celular_solapa {
  position: absolute;
  --ancho: 20vmin;
  top: 0;
  left: calc(55vmin / 2 - var(--ancho) / 2);
  content: '';
  width: var(--ancho);
  height: 3.5vmin;
  background: #2b292a;
  border-radius: 0 0 10vmin 10vmin / 0 0 20vmin 20vmin;
}

.SlideCelularGenerica2022__celular_solapa::before {
  position: absolute;
  content: '';
  width: 0.5vmin;
  height: 0.5vmin;
  border-radius: 50%;
  background: #5a5a5a;
  left: 10.25vmin;
  top: 0.3vmin;
}

.SlideCelularGenerica2022__celular_solapa::after {
  position: absolute;
  content: '';
  width: 10vmin;
  height: 0.75vmin;
  border-radius: 2vmin;
  background: linear-gradient(#2c2c2c, #989898);
  left: 5.5vmin;
  top: 1.5vmin;
}

.SlideCelularGenerica2022__celular_barra_estado {
  height: 5vmin;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2vmin;
  padding: 0 4vmin;
  font-weight: bold;
  color: #2b292a;
}

.SlideCelularGenerica2022__celular_barra_estado_iconos {
  display: grid;
  grid-auto-flow: column;
  gap: 1.5vmin;
}

.SlideCelularGenerica2022__celular_barra_app {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7vmin;
  box-shadow: 0 0.1vmin 0.2vmin rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.SlideCelularGenerica2022__celular_informacion_contacto {
  display: flex;
  align-items: center;
  z-index: 2;
}

.SlideCelularGenerica2022__celular_icono_volver {
  font-size: 6vmin;
  margin-left: -0.25rem;
  color: #44a2ff;
}

.SlideCelularGenerica2022__celular_avatar {
  background: linear-gradient(
    45deg,
    var(--color-rojo) 0 25%,
    var(--color-azul),
    var(--color-purpura) 75%
  );
  background-size: 1000% 1000%;
  animation: Superior__bg 10s ease-in-out infinite;
  width: 5vmin;
  height: 5vmin;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SlideCelularGenerica2022__celular_imagen_avatar {
  width: 3.75vmin;
  min-height: 3.75vmin;
  filter: invert();
}

.SlideCelularGenerica2022__celular_contacto {
  display: grid;
  gap: 0.6vmin;
  padding-left: 1.5vmin;
}

.SlideCelularGenerica2022__celular_nombre_contacto {
  font-size: 2.25vmin;
  font-weight: bold;
  position: relative;
}

.SlideCelularGenerica2022__celular_estado_contacto {
  font-size: 1.8vmin;
  color: gray;
}

.SlideCelularGenerica2022__celular_iconos_barra_app {
  display: grid;
  grid-auto-flow: column;
  gap: 3vmin;
  padding-right: 3vmin;
  color: #44a2ff;
  font-size: 3vmin;
}

.SlideCelularGenerica2022__celular_pantalla {
  flex: 1;
  background: linear-gradient(45deg, #ed332425 0 25%, #005bf125, #a506e925 75%),
    white;
  background-size: 500% 100%;
  animation: Superior__bg 10s ease infinite;
  border-radius: 0 0 5vmin 5vmin;
  display: flex;
  padding: 1.5vmin 2vmin;
  flex-direction: column;
  gap: 1.5vmin;
  position: relative;
}

.SlideCelularGenerica2022__celular_mensaje {
  max-width: 80%;
  padding: 1.15vmin;
  border-radius: 0.5vmin;
  font-size: 2.25vmin;
  line-height: 1.25;
  margin-bottom: 0.75vh;
  position: relative;
  filter: drop-shadow(0 0.1rem 0.15rem rgba(0, 0, 0, 0.1));
}

.SlideCelularGenerica2022__explicacion {
  position: absolute;
  top: 0;
  background-color: var(--color-blanco);
  width: 16vw;
  padding: 1rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  animation: lax 0.5s ease both;
  box-shadow: 0.25rem 0.25rem 0 0.2rem rgba(0, 0, 0, 0.35),
    0 0 2rem rgba(255, 255, 255, 0.5);
}

.SlideCelularGenerica2022__explicacion::after {
  content: '';
  position: absolute;
  width: 2.5rem;
  height: 0.25rem;
  background-color: white;
  top: 1rem;
  left: calc(100% + 0rem);
  box-shadow: 0 0.25rem 0 0 rgba(0, 0, 0, 0.35);
}

.SlideCelularGenerica2022__celular_mensaje--entrante
  .SlideCelularGenerica2022__explicacion {
  right: calc(100% + 2.5rem);
}

.SlideCelularGenerica2022__celular_mensaje--saliente
  .SlideCelularGenerica2022__explicacion {
  left: calc(100% + 2.5rem);
}

.SlideCelularGenerica2022__celular_mensaje--saliente
  .SlideCelularGenerica2022__explicacion::after {
  left: -2.5rem;
}

.SlideCelularGenerica2022__explicacion h2 {
  font-size: 1.25rem;
  font-family: 'Space Grotesk';
  font-weight: bold;
}

.SlideCelularGenerica2022__explicacion p {
  font-size: 1.15rem;
  opacity: 0.7;
}

.SlideCelularGenerica2022__celular_mensaje--saliente::after {
  content: '';
  position: absolute;
  height: 100%;
  width: calc(100% + 30vw);
  left: 0.25vmin;
  top: 0.75vmin;
}

.SlideCelularGenerica2022__celular_mensaje--entrante::before {
  content: '';
  position: absolute;
  width: 2vmin;
  height: 2vmin;
  left: -1.5vmin;
  top: 0;
  background: white;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
}

.SlideCelularGenerica2022__celular_mensaje--entrante {
  transform-origin: 0% 0%;
  background: white;
  align-self: flex-start;
  transform-origin: 0% 0%;
  animation: aparece-mensaje-entrante-slidecelular 0.25s ease both;
}

.SlideCelularGenerica2022__celular_mensaje--entrante::before {
  content: '';
  position: absolute;
  width: 2vmin;
  height: 2vmin;
  left: -1.5vmin;
  top: 0;
  background: inherit;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
}

.SlideCelularGenerica2022__celular_mensaje--saliente {
  transform-origin: 100% 0%;
  background: #dcf8c7;
  align-self: flex-end;
  transform-origin: 100% 0%;
  animation: aparece-mensaje-saliente-slidecelular 0.25s ease both;
}

.SlideCelularGenerica2022__celular_mensaje--saliente::before {
  content: '';
  position: absolute;
  width: 2vmin;
  height: 2vmin;
  left: calc(100% - 0.5vmin);
  top: 0;
  background: inherit;
  clip-path: polygon(100% 0%, 0% 100%, 0% 0%);
}

.SlideCelularGenerica2022__celular strong {
  font-weight: bold;
}

.SlideCelularGenerica2022__celular_deteccion {
  position: absolute;
  height: calc(100% + 0.5vmin);
  top: 0;
  padding-left: 100%;
  margin-left: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Space Grotesk', sans-serif;
  min-width: 50rem;
}

.SlideCelularGenerica2022__celular_etiqueta_deteccion {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 2.5vmin;
  display: flex;
  align-items: center;
}

.SlideCelularGenerica2022__celular_etiqueta_deteccion--corta {
  display: none;
}

.SlideCelularGenerica2022__celular_respuesta_deteccion {
  font-size: 2vmin;
  text-transform: none;
  margin-left: 2rem;
}

.SlideCelularGenerica2022__celular_icono_no {
  color: var(--color-rojo);
  margin-right: 0.5rem;
  font-size: 1.5rem;
}

.SlideCelularGenerica2022__celular_icono_si {
  color: var(--color-verde);
  margin-right: 0.5rem;
  font-size: 1.5rem;
}

.SlideCelularGenerica2022__celular_icono_verificado {
  color: var(--color-verde);
  font-size: 1.5rem;
  position: absolute;
  left: calc(100% + 0.35rem);
  top: -0.15rem;
}

@keyframes aparece-mensaje-entrante-slidecelular {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes aparece-mensaje-saliente-slidecelular {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
  }
}

@keyframes lax {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 0.9;
    transform: scale(1);
  }
}

@keyframes Superior__bg {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
