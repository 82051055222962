.HeroImagen {
  display: grid;
  place-items: center start;
  position: relative;
  z-index: 3;
  font-family: Helvetica;
}

.HeroImagen__agenda {
  border-radius: 1.25rem 1.25rem .15rem .15rem;
  width: 30rem;
  height: 20rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  box-shadow:
    -.15rem .15rem .5rem rgba(0, 0, 50, .05),
    -.35rem .35rem .5rem rgba(0, 0, 50, .05),
    0 0 0 .35rem lightgray;
  background: white;
  color: black;
}

.HeroImagen__agenda_superior {
  background-color: aquamarine;
  height: 2rem;
  border-radius: 1.25rem 1.25rem 0 0;
  box-shadow: 0 0 0 .35rem lightgray;
}

.HeroImagen__agenda_contenedor_slots {
  display: grid;
  grid-template: 1fr 1fr 1fr / 1fr 1fr 1fr;
  gap: 1rem 2rem;
  padding: 1.5rem 2rem;
  flex: 1;
}

.HeroImagen__agenda_slot {
  place-self: stretch stretch;
  border-radius: .25rem;
  box-shadow:
    -.15rem .15rem .5rem rgba(0, 0, 50, .05),
    -.35rem .35rem .5rem rgba(0, 0, 50, .05);
  position: relative;
}

.HeroImagen__agenda_slot::before {
  content: '';
  width: 1.5rem;
  height: 1.5rem;
  background-color: paleturquoise;
  border-radius: 50%;
  position: absolute;
  left: 1.25rem;
  top: calc(50% - .75rem);
}

.HeroImagen__agenda_slot::after {
  content: '';
  width: 2.75rem;
  height: 1.25rem;
  background-color: lightblue;
  border-radius: .25rem;
  position: absolute;
  left: 3.6rem;
  top: calc(50% - .625rem);
}

.HeroImagen__agenda_slot--selected {
  box-shadow:
    0 0 0 .1rem gray,
    -.15rem .15rem .5rem rgba(0, 0, 50, .05),
    -.35rem .35rem .5rem rgba(0, 0, 50, .05);
}

.HeroImagen__agenda_slot--bw::before {
  background-color: rgb(240, 240, 240);
}

.HeroImagen__agenda_slot--bw::after {
  background-color: rgb(230, 230, 230);
}

.HeroImagen__agenda_slot_check {
  width: 1.75rem;
  height: 1.75rem;
  background-color: lightgreen;
  position: absolute;
  right: -.75rem;
  top: -.75rem;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

.HeroImagen__celular {
  position: absolute;
  width: 12rem;
  height: 25rem;
  background: linear-gradient(lightgray 0 1rem, lightcyan 0 3rem, white 0);
  border-radius: 1.15rem 1.15rem 0 0;
  box-shadow: 0 0 0 .6rem black;
  clip-path: polygon(
    -2.5rem -2.5rem,
    calc(100% + 2.5rem) -2.5rem,
    calc(100% + 2.5rem) calc(100% - 2.5rem),
    -2.5rem calc(100% - 2.5rem)
  );
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding-top: 2rem;
}

.HeroImagen__celular--3 {
  right: -15rem;
  top: 20%;
}

.HeroImagen__celular--5 {
  right: -6rem;
  top: 55%;
}

.HeroImagen__celular--6 {
  right: 35%;
  top: 65%;
}

.HeroImagen__mensaje {
  padding: .5rem;
  font-size: .75rem;
  box-shadow: 0 .15rem .35rem rgba(0, 0, 0, .25);
  line-height: 1.1;
}

.HeroImagen__mensaje--entrante {
  background-color: rgb(245, 245, 245);
  transform: translateX(-1.5rem);
  align-self: flex-start;
}

.HeroImagen__mensaje--saliente {
  background-color: lightgoldenrodyellow;
  transform: translateX(1.5rem);
  align-self: flex-end;
}

.HeroImagen__mensaje strong {
  font-weight: bold;
}

@media (orientation: portrait) {
  .HeroImagen {
    display: none;
  }
}