.Modal__overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.35);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  pointer-events: none;
  opacity: 0;
  transition: all 0.15s ease;
}

.Modal__overlay--activo {
  pointer-events: all;
  opacity: 1;
}

.Modal {
  position: absolute;
  
  top: 2rem;
  left: 2rem;
  right: 2rem;
  bottom: 2rem;
  border-radius: 1rem;
  display: flex;
  clip-path: inset(0% 0% 0% 0% round 10px);
  opacity: 0;
  box-shadow: 0 0.25rem 0.15rem rgba(0, 0, 0, 0.15),
    0 0.25rem 1rem rgba(0, 0, 0, 0.15);
  z-index: 20;
  transition: all 0.15s ease;
}

.Modal--visibles {
  opacity: 1;
}

.Modal__boton_cerrar {
  right: 0.5rem;
  top: 0.5rem;
  position: absolute;
  font-size: 1.5rem;
  opacity: 0.5;
}

.Modal__boton_cerrar {
  color: white;
  border: none;
  background: none;
  opacity: 0.8;
  font-size: 2rem;
  cursor: pointer;
  z-index: 2;
}

.Modal__boton_cerrar:hover {
  opacity: 1;
}
