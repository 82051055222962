.Integraciones__fondo {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: lightgray;
  --int-padding: 6rem;
  padding: 8rem 0;
  clip-path: polygon(0% 0%, 100% var(--int-padding), 100% calc(100% - var(--int-padding)), 0% 100%);
  background: linear-gradient(
    45deg,
    var(--color-rojo) 0 25%,
    var(--color-azul),
    var(--color-purpura) 75%
  );
  background-size: 400% 400%;
  animation: Superior__bg 10s ease-in-out infinite;
}

.Integraciones {
  width: 100%;
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  padding: 5rem 8rem;
  place-items: center center;
  gap: 5rem;
  color: white;
}

.Integraciones__contenedor_grafica {
  place-self: stretch stretch;
}

.Integraciones__textos {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  line-height: 1.35;
  font-size: 1.25rem;
}

.Integraciones__subtitulo {
  font-weight: bold;
  font-size: 1.5rem;
}

.Integraciones__contenedor_botones {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.Integraciones__boton {
  background-color: black;
  color: white;
  padding: .75rem 1.5rem;
  border-radius: .35rem;
  font-weight: 600;
  font-size: 1.25rem;
}

.Integraciones__boton--secundario {
  background-color: gray;
}

@media (orientation: portrait) {
  .Integraciones__contenedor_grafica {
    display: none;
  }

  .Integraciones__subtitulo {
    font-size: 2.5rem;
  }

  .Integraciones__textos {
    grid-column: 1 / -1;
    place-self: center center;
    text-align: center;
    font-size: 2rem;
  }

  .Integraciones__contenedor_botones {
    flex-direction: column;
  }

  .Integraciones__boton {
    text-align: center;
    font-size: 2rem;
    padding: 2rem 1.5rem;
  }
}